<template>
  <div>
    <Header />
    <div id="myChart" class="terminal-chart"></div>
  </div>
</template>

<script>
const echarts = require('echarts')
require('echarts/lib/chart/pie')
require('echarts/lib/component/tooltip')
import Header from '../../../components/header';
export default {
  components: {
    Header,
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine(){
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
          color: ['#F56C6C', '#E5A23C'],
          title: { 
            subtext: '终端销量区间占比',
            textStyle: {
              fontSize: '13px',
            },
            x: 'center'
          },
          tooltip: {},
          legend: {
            show: true,
            bottom: 14,
            itemWidth: 12,
            itemHeight: 12,
            textStyle: {
              fontSize: '12px',
            }
          },
          series: [{
            name: '终端销量区间占比',
            type: 'pie',
            radius: '60%',
            center: ['50%', '50%'],
            data: [
              {value: 1048, name: '0'},
              {value: 300, name: '>100'}
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
              normal:{ 
                label:{ 
                  show: true, 
                  formatter: '{b}：{d}%' 
                }, 
                labelLine :{show:true} 
              } 
          }
          }]
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.terminal-chart {
  width: 100%;
  height: 300px;;
}
</style>